.dashboard-container {
  width: 100%;
  background-color: #14161b;
}

.section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.footer-container {
  background-color: rgb(19, 26, 34);
}

.join-us-top-view {
  padding-top: 100px;
  height: 75vh;
  background-image: url("/public/backgrounds/teamback.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.product-top-view {
  height: 75vh;
  background-image: url("/public/backgrounds/tech_back.jpg");
}

.dashboard-video-view {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.8) 0,
    rgba(0, 0, 0, 0) 60%,
    rgba(0, 0, 0, 0.8) 100%
  );
}

.fade-anim {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-in {
  opacity: 1;
}
