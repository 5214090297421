.outer-profile-circle {
  display: flex;
  justify-content: center;
  align-items: center; /* Center content vertically if needed */
  border-radius: 58px;
  padding: 2px;
  border: 3px solid black; /* Add border style and color */
}

.profile-container {
  width: 75%;
  background-color: black;
}

.slider-container {
  padding: 100px;
  background-color: #14161b;
  width: 100%;
}
.nav-link {
  margin: 7px;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px; /* Set a base font size */
  font-family: "Oswald", sans-serif; /* Ensure Oswald is properly loaded */
}

.nav-link:hover {
  color: orange;
}
.nav-link-dropdown {
  margin: 8px;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px; /* Set a base font size */
  font-family: "Oswald", sans-serif; /* Ensure Oswald is properly loaded */
}

.nav-link-dropdown:hover {
  color: orange;
}
