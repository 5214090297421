/* src/index.css */
/* @tailwind base;
@tailwind components;
@tailwind utilities; */

/* Custom global styles */
body {
  margin: 0;
  padding: 0;
  background-color: #14161b;
}

